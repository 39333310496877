<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="$vuetify.theme.themes.light.accent"
        v-bind="attrs"
        v-on="on"
        text
        class="email-menu-btn"
      >
        {{ $store.state.userProfile.email }}
        <v-icon>{{ mdiChevronDown }}</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-subheader>{{ $store.state.userProfile.email }}</v-subheader>
      <v-list-item-group>
        <v-list-item @click="$store.dispatch('goToPassepartoutApp')">
          <v-list-item-content>
            <v-list-item-title>
              Voir mon historique
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="$store.state.keycloak.logout({ redirectUri: $store.getters.keycloakRedirectUri })">
          <v-list-item-content>
            <v-list-item-title>
              Me déconnecter
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import {
  VMenu,
  VList,
  VListItem,
  VListItemGroup,
  VListItemContent,
  VListItemTitle,
} from "vuetify/lib";
import {
  mdiChevronDown
} from '@mdi/js'

export default {
  name: "ProfileMenu",
  components: {
    VList,
    VListItem,
    VListItemGroup,
    VListItemContent,
    VListItemTitle,
    VMenu,
  },
  data() {
    return {
      mdiChevronDown
    }
  },
}
</script>

<style scoped>
.email-menu-btn {
  text-transform: lowercase;
  letter-spacing: normal;
}
</style>
