export const getCardProviderLogo = (cardProvider) => {
    const cardProviderLogos = {
        VISA: "https://logo.clearbit.com/visa.com",
        MASTERCARD: "https://logo.clearbit.com/mastercard.com"
    }
    try {
        return cardProviderLogos[cardProvider]
    } catch (err) {
        return null
    }
}