<template>
  <div>
    <svg :fill="fill" width="100%" height="100%" viewBox="0 0 74 74" xmlns="http://www.w3.org/2000/svg">
      <path d="M59.0039 30.3398C59.0039 43.4727 50.3359 54.6172 38.418 58.3594L34.8789 42.0156C35.8594 41.4453 36.7539 40.7539 37.5508 39.957C39.9336 37.5703 41.4102 34.2812 41.4102 30.6406C41.4102 23.3594 35.5117 17.4648 28.2344 17.4648C24.5938 17.4648 21.3008 18.9375 18.918 21.3203C16.5312 23.707 15.0586 27 15.0586 30.6406C15.0586 33.9727 16.293 37.0156 18.3359 39.3359C18.5234 39.5469 18.7148 39.7539 18.918 39.957C19.4844 40.5234 20.0977 41.0391 20.7617 41.4883C20.9492 41.625 21.1406 41.7461 21.332 41.8633L20.7617 44.3086L17.1758 59.6992L13.8633 73.9258H0.296875V59.6992H0.277344V0.980469H29.6367C45.8281 0.980469 59.0039 14.1523 59.0039 30.3398Z" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "PassepartoutSmallLogo",
  props: ["fill"],
};
</script>
