<template>
  <div class="bottom-card">
    <v-row
      align="center"
      class="bottom-card-header"
    >
      <ProfileMenu v-if="$store.state.keycloak.authenticated && !$store.state.isTempEmail" />

      <component
        :is="$vuetify.breakpoint.width <= 370 ? 'PassepartoutSmallLogo': 'PassepartoutFullLogo'"
        v-else
        class="passepartout-logo"
        :fill="this.$vuetify.theme.themes.light.accent"
      />

      <v-spacer />

      <v-btn
        v-if="!$store.state.keycloak.authenticated"
        text
        small
        @click="$store.state.isBottomCardToggled = !$store.state.isBottomCardToggled"
        class="text-none text-decoration-underline"
        style="color: var(--v-accent-base)"
      >
        En savoir plus
        <v-icon
          right
          v-if="$store.state.isBottomCardToggled"
        >
          {{ mdiChevronUp }}
        </v-icon>
        <v-icon
          right
          v-else
        >{{ mdiChevronDown }}</v-icon>
      </v-btn>

      <v-btn
        icon
        style="color: var(--v-accent-base)"
        class="ml-2"
        @click="$store.commit('closeDialog')"
      >
        <v-icon>{{ mdiClose }}</v-icon>
      </v-btn>
    </v-row>

    <div
      v-if="!$store.state.keycloak.authenticated"
      :class="{'closed': $store.state.isBottomCardToggled}"
      class="bottom-card-slider slider"
      align="center"
    >
      <v-col 
        align="center"
        class="bottom-card-description text-body-1"
      >
        <NewspaperImage
          class="mt-2 mb-3"
          height="70"
        />
        <div class="know-more-text">
            <span>
              Achetez vos articles en un clic sur des dizaines de journaux avec PassePartout.
              <br />
              Simple, sécurisé et sans engagement !
            </span>
        </div>
        <div class="know-more-text">
          <span>
            Plus d'informations sur <a target="_blank" href="https://passepartout.io/a-propos">PassePartout</a> 
          </span>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import ProfileMenu from "./ProfileMenu.vue";
import PassepartoutFullLogo from "../../public/PassepartoutFullLogo.vue";
import PassepartoutSmallLogo from "../../public/PassepartoutSmallLogo.vue";
import NewspaperImage from "../assets/NewspaperImage.vue";
import {
  mdiChevronDown,
  mdiChevronUp,
  mdiClose
} from '@mdi/js'

export default {
  name: "BottomCard",
  components: {
    PassepartoutFullLogo,
    PassepartoutSmallLogo,
    ProfileMenu,
    NewspaperImage,
  },
  data() {
    return {
      mdiChevronDown,
      mdiChevronUp,
      mdiClose,
    }
  },
};
</script>

<style lang="scss">
.passepartout-logo {
  height: 20px;
  display: block;
  margin-left: 4px;
}
.bottom-card {
  border-radius: 20px 20px 0px 0px !important;
  background-color: var(--v-primary-base);
}

.bottom-card-header {
  text-align: center;
  font-weight: bolder;
  font-size: medium;
  margin: 0px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 50px;
}

.bottom-card-description {
  align-items: center;
  max-width: 95%;
  color: white;
  a {
    color: white !important;
    text-decoration-color: var(--v-accent-lighten2);
    text-decoration-thickness: 3px;
  }
  a:hover {
    color: var(--v-accent-lighten2) !important;
  }
}

.know-more-text {
  margin-bottom: 10px;
  margin-top: 10px;
}

.slider {
  max-height: 300px;
  height: auto;
  transition: max-height 0.5s;
}

.slider.closed {
  padding: 0;
  height: auto;
  max-height: 0px;
}

</style>
