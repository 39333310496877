import { render, staticRenderFns } from "./ProfileMenu.vue?vue&type=template&id=72ed1bb2&scoped=true"
import script from "./ProfileMenu.vue?vue&type=script&lang=js"
export * from "./ProfileMenu.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./ProfileMenu.vue?vue&type=style&index=0&id=72ed1bb2&prod&scoped=true&lang=css")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "72ed1bb2",
  null
  ,true
)

export default component.exports