import store from './store/index.js';

export default class PassepartoutWidget {
    static async checkAccessRight() {
        return await store.dispatch('checkAccessRight');
    }

    static openWidget() {
        store.dispatch('openDialog');
    }

    static closeWidget() {
        store.commit('closeDialog');
    }

    static get isWidgetOpen() {
        return store.state.isDialogOpen;
    }

    static get getState() {
        return {
            userId: store.state.userProfile.id,
            contentId: store.state.contentId,
            contentTitle: store.state.contentTitle,
            contentLede: store.state.contentLede,
            contentPriceInCents: store.state.contentPriceInCents,
            contentUrl: store.state.contentUrl,
            contentThumbnail: store.state.contentThumbnail,
            publisherId: store.state.publisherId,
            publisherName: store.state.publisher.name,
            apiKey: store.state.publisher.apiKey,
            token: store.state.keycloak.token,
            refreshToken: store.state.keycloak.refreshToken,
            idToken: store.state.keycloak.idToken,
        };
    }

    static SetConfig(paramsToSet) {
        if (Object.keys(paramsToSet).some(
            key => ![
                'contentId',
                'contentTitle',
                'contentLede',
                'contentPriceInCents',
                'contentUrl',
                'contentThumbnail',
                'apiKey',
                'publisherId',
            ].some(authorizedValue => key === authorizedValue)
        )) {
            throw new Error('One of the parameters used is not authorized.');
        }

        Object.entries(paramsToSet).forEach((entry) => {
            store.commit(
                'setStoreParam',
                { key: entry[0], value: entry[1] },
            );
        });
    }
}
