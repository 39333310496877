<template>
  <div>
    <svg :fill="fill" width="100%" height="100%" viewBox="0 0 522 74" xmlns="http://www.w3.org/2000/svg">
      <path d="M59.0039 30.3398C59.0039 43.4727 50.3359 54.6172 38.418 58.3594L34.8789 42.0156C35.8594 41.4453 36.7539 40.7539 37.5508 39.957C39.9336 37.5703 41.4102 34.2812 41.4102 30.6406C41.4102 23.3594 35.5117 17.4648 28.2344 17.4648C24.5938 17.4648 21.3008 18.9375 18.918 21.3203C16.5312 23.707 15.0586 27 15.0586 30.6406C15.0586 33.9727 16.293 37.0156 18.3359 39.3359C18.5234 39.5469 18.7148 39.7539 18.918 39.957C19.4844 40.5234 20.0977 41.0391 20.7617 41.4883C20.9492 41.625 21.1406 41.7461 21.332 41.8633L20.7617 44.3086L17.1758 59.6992L13.8633 73.9258H0.296875V59.6992H0.277344V0.980469H29.6367C45.8281 0.980469 59.0039 14.1523 59.0039 30.3398Z" />
      <path d="M97.5586 34.8555C103.176 34.8555 105.66 32.0859 105.66 27.6758C105.66 23.1289 103.176 20.4258 97.5586 20.4258H89.668V34.8555H97.5586ZM97.9844 42.8906H89.668V61.9453H79.7148V12.3203H97.9844C109.93 12.3203 115.898 19.0742 115.898 27.6758C115.898 35.2109 110.781 42.8906 97.9844 42.8906Z" />
      <path d="M137.609 30.6641C132.273 30.6641 127.227 34.6445 127.227 42.1094C127.227 49.5742 132.273 53.8398 137.609 53.8398C143.082 53.8398 148.059 49.7148 148.059 42.25C148.059 34.7852 143.082 30.6641 137.609 30.6641ZM135.047 21.918C141.375 21.918 145.711 24.9063 148.059 28.1758V22.5586H158.082V61.9453H148.059V56.1836C145.711 59.5977 141.234 62.582 134.977 62.582C125.023 62.582 117.062 54.4102 117.062 42.1094C117.062 29.8086 125.023 21.918 135.047 21.918Z" />
      <path d="M179.578 62.582C169.695 62.582 162.801 56.8242 162.371 49.5039H172.398C172.754 52.4883 175.523 54.7656 179.508 54.7656C183.418 54.7656 185.551 52.9844 185.551 50.7109C185.551 43.6719 163.297 48.1523 163.297 33.7188C163.297 27.1797 169.129 21.918 178.867 21.918C188.395 21.918 194.152 27.1094 194.719 34.9297H185.195C184.91 31.8711 182.492 29.8086 178.582 29.8086C174.887 29.8086 172.965 31.3008 172.965 33.5781C172.965 40.7578 194.793 36.2773 195.074 50.7109C195.074 57.4648 189.176 62.582 179.578 62.582Z" />
      <path d="M215.008 62.582C205.125 62.582 198.23 56.8242 197.805 49.5039H207.828C208.184 52.4883 210.957 54.7656 214.938 54.7656C218.848 54.7656 220.98 52.9844 220.98 50.7109C220.98 43.6719 198.727 48.1523 198.727 33.7188C198.727 27.1797 204.559 21.918 214.297 21.918C223.824 21.918 229.582 27.1094 230.148 34.9297H220.625C220.34 31.8711 217.922 29.8086 214.012 29.8086C210.316 29.8086 208.395 31.3008 208.395 33.5781C208.395 40.7578 230.223 36.2773 230.504 50.7109C230.504 57.4648 224.605 62.582 215.008 62.582Z" />
      <path d="M242.973 38.5547H261.668C261.527 33.3633 257.402 30.0938 252.285 30.0938C247.52 30.0938 243.754 33.1523 242.973 38.5547ZM252.57 62.582C241.125 62.582 232.805 54.6211 232.805 42.25C232.805 29.8086 240.91 21.918 252.57 21.918C263.945 21.918 271.977 29.668 271.977 41.3984C271.977 42.6758 271.906 43.957 271.691 45.2383H242.902C243.398 51.0664 247.379 54.3359 252.355 54.3359C256.621 54.3359 258.969 52.2031 260.246 49.5742H270.98C268.848 56.8242 262.309 62.582 252.57 62.582Z" />
      <path d="M294.941 34.8555C300.559 34.8555 303.047 32.0859 303.047 27.6758C303.047 23.1289 300.559 20.4258 294.941 20.4258H287.051V34.8555H294.941ZM295.367 42.8906H287.051V61.9453H277.098V12.3203H295.367C307.312 12.3203 313.285 19.0742 313.285 27.6758C313.285 35.2109 308.164 42.8906 295.367 42.8906Z" />
      <path d="M335.582 30.6641C330.25 30.6641 325.203 34.6445 325.203 42.1094C325.203 49.5742 330.25 53.8398 335.582 53.8398C341.059 53.8398 346.035 49.7148 346.035 42.25C346.035 34.7852 341.059 30.6641 335.582 30.6641ZM333.023 21.918C339.352 21.918 343.688 24.9063 346.035 28.1758V22.5586H356.059V61.9453H346.035V56.1836C343.688 59.5977 339.207 62.582 332.953 62.582C323 62.582 315.035 54.4102 315.035 42.1094C315.035 29.8086 323 21.918 333.023 21.918Z" />
      <path d="M372.434 61.9453H362.48V22.5586H372.434V28.6719C374.922 24.6211 379.047 21.9883 384.52 21.9883V32.4414H381.891C375.988 32.4414 372.434 34.7148 372.434 42.3203V61.9453Z" />
      <path d="M388.859 30.7344V12.8203H398.883V22.5586H407.699V30.7344H398.883V49.7852C398.883 52.418 399.949 53.5547 403.074 53.5547H407.699V61.9453H401.441C393.906 61.9453 388.859 58.7461 388.859 49.7148V30.7344Z" />
      <path d="M430.234 53.9102C435.496 53.9102 440.543 50.0703 440.543 42.25C440.543 34.3594 435.637 30.5898 430.375 30.5898C425.113 30.5898 420.352 34.3594 420.352 42.25C420.352 50.0703 424.902 53.9102 430.234 53.9102ZM430.234 62.582C418.859 62.582 410.258 54.6211 410.258 42.25C410.258 29.8086 419.145 21.918 430.52 21.918C441.895 21.918 450.781 29.8086 450.781 42.25C450.781 54.6211 441.68 62.582 430.234 62.582Z" />
      <path d="M493.152 61.9453H483.129V56.9688C480.641 60.3086 476.301 62.4414 471.398 62.4414C462.227 62.4414 455.613 56.4688 455.613 45.6641V22.5586H465.566V44.2422C465.566 50.5703 469.051 53.9805 474.312 53.9805C479.715 53.9805 483.129 50.5703 483.129 44.2422V22.5586H493.152V61.9453Z" />
      <path d="M502.395 30.7344H497.703V22.5586H502.395V12.8203H512.422V22.5586H521.234V30.7344H512.422V49.7852C512.422 52.418 513.484 53.5547 516.613 53.5547H521.234V61.9453H514.98C507.445 61.9453 502.395 58.7461 502.395 49.7148V30.7344Z" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "PassepartoutFullLogo",
  props: ["fill"],
};
</script>
