<template>
  <svg width="100%" height="100%" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.9684 60.4136C48.5737 60.4136 60.4136 48.5737 60.4136 33.9684C60.4136 19.3631 48.5737 7.52319 33.9684 7.52319C19.3631 7.52319 7.52319 19.3631 7.52319 33.9684C7.52319 48.5737 19.3631 60.4136 33.9684 60.4136Z"
      fill="url(#paint0_linear_231_486)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.969 67.938C52.7299 67.938 67.938 52.7299 67.938 33.969C67.938 15.2081 52.7299 0 33.969 0C15.2081 0 0 15.2105 0 33.9714C0 52.7299 15.2081 67.938 33.969 67.938ZM33.969 60.4142C48.574 60.4142 60.4142 48.574 60.4142 33.969C60.4142 19.364 48.574 7.52624 33.969 7.52624C19.364 7.52624 7.52381 19.364 7.52381 33.969C7.52381 48.574 19.364 60.4166 33.969 60.4166V60.4142Z"
      fill="url(#paint1_linear_231_486)"
    />
    <path
      d="M40.5913 48.0139C38.6254 46.1167 35.8008 41.778 35.8008 41.778C35.8008 41.778 41.6086 36.4194 41.997 36.3692C42.3855 36.319 48.9596 39.7778 48.9596 39.7778C48.9596 39.7778 47.8868 46.5025 46.0609 47.6572C44.2298 48.8119 40.5913 48.0139 40.5913 48.0139Z"
      fill="#A3A3A3"
    />
    <path
      d="M41.1312 38.4753C41.1312 38.4753 39.5749 40.3065 38.9196 42.5921C38.4624 44.1854 38.5496 45.6493 38.5496 45.6493L36.5547 42.9858L38.0265 40.8534L41.1312 38.4753Z"
      fill="#646464"
    />
    <path
      d="M20.8664 23.2661C19.2704 27.0869 19.252 31.9884 19.252 31.9884L26.883 35.5477L33.8905 46.9599C33.8905 46.9599 36.8076 42.7216 37.5078 41.0781L20.8664 23.2661Z"
      fill="#A3A3A3"
    />
    <path
      d="M42.1991 40.6314L46.6276 46.6294C46.6276 46.6294 50.8157 40.3724 46.802 37.635C45.8983 37.0193 42.0775 36.3561 41.5781 36.5199L42.1991 40.6314Z"
      fill="#646464"
    />
    <path
      d="M32.6861 46.9598L32.6491 46.9651C32.6121 46.9889 32.5724 47.01 32.5355 47.0338C32.5804 47.0127 32.6412 46.981 32.6861 46.9598ZM46.5927 35.5133C45.7418 35.0006 29.8112 26.0114 27.914 24.6506L19.8734 25.3456L18.0581 27.544L18.6579 28.231L18 29.7609L27.0843 34.8527C27.6154 36.1342 30.6277 42.9356 32.1048 46.4049C32.1048 46.4049 34.6335 44.2012 35.7987 42.6185L33.891 46.9572L36.2083 48.0643C36.2083 48.0643 36.6205 42.888 39.7384 39.7621C42.8115 36.6838 44.9491 37.2255 46.3548 38.7712C48.585 41.2233 46.3601 46.1011 43.7574 45.9082C41.7387 45.7602 43.0731 42.7374 42.9542 40.7583C42.8432 38.9007 41.9791 38.2745 41.7387 38.2137C41.4982 38.1529 40.5919 39.4107 40.296 40.5733C38.8189 46.3653 40.0344 48.4792 42.1985 48.8914C45.4617 49.5097 47.9931 47.4434 49.1795 43.8498C50.4055 40.1347 49.4781 37.2493 46.5927 35.5133Z"
      fill="#CFCFCF"
    />
    <path
      d="M41.6912 39.947C41.6912 39.947 42.1933 41.8389 41.5617 44.2065C41.2341 43.567 41.242 42.666 41.3081 41.9499C41.3953 40.9881 41.6912 39.947 41.6912 39.947ZM42.624 46.9598C42.9225 47.0311 43.2106 47.1025 43.5012 47.113C43.7892 47.1289 44.072 47.1051 44.3441 47.0443C44.8858 46.9096 45.3799 46.6321 45.8185 46.2675C46.2624 45.9108 46.6588 45.4801 47.0314 45.0256C47.4039 44.5685 47.7554 44.0876 48.1068 43.5855C47.9562 44.18 47.6946 44.7428 47.3722 45.266C47.0469 45.8004 46.6363 46.2779 46.1567 46.6797C45.6653 47.076 45.0655 47.3852 44.4128 47.4512C44.0878 47.4829 43.7628 47.4486 43.4589 47.3667C43.1524 47.2821 42.875 47.1342 42.624 46.9598Z"
      fill="#A3A3A3"
    />
    <path
      d="M46.7055 35.5475C46.7055 35.5475 42.7737 31.5999 37.0715 27.935C37.0715 27.935 31.8899 20.8615 31.298 20.1903C30.1618 18.9009 27.8445 18.8269 25.905 19.1862C21.9072 19.9287 19.5291 22.8776 18.0098 25.2768L26.9514 33.6953L31.9296 44.2012C34.49 42.1692 35.0607 39.511 38.7573 36.9585C43.3418 33.7904 46.7055 35.5475 46.7055 35.5475Z"
      fill="#EEEEEE"
    />
    <path
      d="M38.386 39.2652C38.6582 38.898 38.9462 38.5333 39.2712 38.203C39.6121 37.8912 39.9424 37.5583 40.3414 37.3046C40.7113 37.0113 41.1446 36.8132 41.5674 36.5886C41.7867 36.4961 42.0166 36.4247 42.2438 36.3402C42.3548 36.2953 42.4711 36.2635 42.59 36.2398L42.9441 36.1579C43.1792 36.0997 43.425 36.0918 43.6681 36.0707C43.9085 36.0416 44.1542 36.0548 44.3973 36.0707C44.8835 36.1077 45.3671 36.2133 45.8189 36.3877C45.9325 36.43 46.0409 36.4881 46.1518 36.5383C46.2602 36.5938 46.3738 36.6335 46.4716 36.7075C46.675 36.8396 46.8785 36.9664 47.0582 37.1276C47.4149 37.442 47.7452 37.8093 47.9011 38.2559L47.8561 38.2823C47.6183 37.9018 47.2696 37.6059 46.8996 37.3575C46.5323 37.1012 46.1149 36.9294 45.6921 36.7867C44.836 36.5278 43.9164 36.504 43.0418 36.6784L42.7142 36.7471C42.6032 36.7656 42.4949 36.7947 42.3918 36.8343C42.1804 36.9056 41.9664 36.9743 41.755 37.0483C41.3481 37.2359 40.9332 37.4103 40.558 37.6587C40.1617 37.8727 39.8103 38.1555 39.4456 38.417C39.0889 38.6892 38.7639 39.0036 38.423 39.2943L38.386 39.2652ZM18.0586 27.5386L23.3961 31.9037L18.4576 28.7276C18.4576 28.7276 18.558 28.4898 18.6056 28.3577L18.0586 27.5386Z"
      fill="#A3A3A3"
    />
    <path
      opacity="0.7"
      d="M29.095 20.0449L31.8536 23.4852C32.0227 23.4747 32.1812 23.4694 32.3345 23.4694L29.6129 20.0766C29.4728 20.0608 29.2958 20.0502 29.095 20.0449ZM26.7301 20.2774L29.9405 23.9133C30.07 23.8604 30.1995 23.8129 30.3289 23.7732L27.1661 20.1902C27.0234 20.2167 26.878 20.2457 26.7301 20.2774ZM33.4865 24.7113C33.3359 24.7139 33.1826 24.7192 33.0241 24.7298C33.8855 26.006 35.0851 27.565 36.4063 28.6325C36.5886 28.6034 36.7709 28.5823 36.9585 28.5691C35.6347 27.5941 34.3955 26.0298 33.4865 24.7113ZM31.5444 24.9623C31.4149 24.9966 31.2855 25.0336 31.156 25.0759C31.9143 26.2438 33.1298 27.9878 34.3902 29.2534C34.5144 29.1953 34.6412 29.1398 34.768 29.087C33.542 27.8821 32.3212 26.1513 31.5444 24.9623ZM29.8956 25.6731C29.8057 25.7312 29.7186 25.7893 29.634 25.8528C29.6155 25.866 29.597 25.8818 29.5812 25.895C30.2682 26.9388 31.5629 28.8227 32.7969 30.1994C32.8999 30.1254 33.0056 30.0514 33.1139 29.9774C31.8985 28.6272 30.5852 26.7168 29.8956 25.6731ZM28.2785 27.1501C28.9787 28.1622 30.2919 30.0197 31.4202 31.3567C31.5101 31.2695 31.6052 31.1823 31.7003 31.0951C30.646 29.84 29.3645 28.0485 28.5427 26.8595C28.4555 26.952 28.3657 27.0497 28.2785 27.1501ZM27.1238 28.6087C27.9297 29.7238 29.2218 31.4862 30.1942 32.6885C30.2734 32.5907 30.358 32.4903 30.4452 32.3899C29.6234 31.362 28.4767 29.8268 27.3642 28.2837C27.2797 28.3947 27.2004 28.503 27.1238 28.6087ZM26.0642 30.1835L29.095 34.2052C29.1452 34.1233 29.2192 34.0096 29.3169 33.8617L26.2835 29.8348C26.1858 29.988 26.1118 30.1069 26.0642 30.1835Z"
      fill="#A3A3A3"
    />
    <path
      d="M34.7912 30.9102L33.9668 31.5522L37.0213 35.8777C37.0213 35.8777 37.6951 35.2224 38.1813 35.0428L34.7912 30.9102Z"
      fill="#A3A3A3"
    />
    <path
      d="M28.7298 24.4737C25.8365 26.6827 24.7188 29.1691 24.7188 29.1691L20.3906 25.1131C20.3906 25.1131 22.6604 21.4086 25.3582 20.6846L28.7298 24.4737Z"
      fill="#C2C2C2"
    />
    <path
      d="M19.0651 26.286L18.748 26.6929L23.2744 30.2416L19.0651 26.286ZM20.3916 25.1128L24.7197 29.1688C24.7197 29.1688 25.3486 27.771 26.8838 26.1407L26.8679 24.2593L23.2717 25.0203L23.2744 23.6807L21.3481 23.8101C20.7562 24.5447 20.3916 25.1128 20.3916 25.1128Z"
      fill="#A3A3A3"
    />
    <path
      opacity="0.7"
      d="M35.9093 30.3712L39.6165 34.4562C39.7935 34.3875 39.9705 34.3294 40.1423 34.2818L36.451 30.2179L35.9093 30.3712ZM38.4089 30.0145C38.1975 30.0013 37.9571 30.0013 37.6981 30.0171L41.9311 33.8776C41.9311 33.8776 42.3618 33.7375 42.6261 33.8274L38.4089 30.0145ZM32.6856 32.8814L35.4495 37.4659C35.5658 37.3232 35.682 37.1831 35.8009 37.0457L33.0608 32.5036C32.9367 32.6251 32.8098 32.7519 32.6856 32.8814ZM31.2958 34.3664L33.8747 39.4159C34.0042 39.2626 34.1257 39.1147 34.234 38.9826L31.6683 33.9568C31.5415 34.0942 31.4173 34.2316 31.2958 34.3664ZM29.8848 35.9888L32.1994 41.4056C32.3078 41.2761 32.4346 41.1255 32.572 40.9643L30.2573 35.5502C30.0988 35.7325 29.972 35.8831 29.8848 35.9888Z"
      fill="#A3A3A3"
    />
    <path
      d="M27.0349 36.0947L26.6438 35.5107C26.609 35.4606 26.594 35.3995 26.6015 35.339C26.6596 34.7761 27.1485 31.8537 31.1542 29.2035C34.185 27.1979 36.2777 27.3591 36.7718 27.4146C36.8405 27.4225 36.904 27.4569 36.9515 27.5071L37.4456 28.0567C37.4958 28.1122 37.4588 28.202 37.3822 28.202C36.6979 28.1994 34.3435 28.3209 31.7223 30.0569C28.1367 32.4298 27.3044 35.3865 27.1538 36.0735C27.1379 36.129 27.0666 36.1396 27.0349 36.0947Z"
      fill="#DEA076"
    />
    <path
      d="M37.3793 28.2018C36.8086 28.2097 36.2405 28.2942 35.6882 28.4264C35.136 28.5532 34.5917 28.7276 34.0685 28.9443C33.0192 29.3751 32.0305 29.9409 31.1276 30.6274C30.2116 31.2964 29.3949 32.0917 28.7019 32.9897C28.3558 33.4389 28.0466 33.9145 27.785 34.4192C27.5181 34.9212 27.2988 35.4497 27.1641 36.0046C27.3437 34.8684 27.8828 33.8114 28.546 32.8708C29.2172 31.9301 30.0574 31.1189 30.9769 30.4213C31.9044 29.7396 32.9164 29.1583 33.9998 28.7593C35.0778 28.3656 36.2325 28.1199 37.3793 28.2018Z"
      fill="#C17E52"
    />
    <defs>
      <linearGradient
        id="paint0_linear_231_486"
        x1="23.1787"
        y1="47.1922"
        x2="51.7517"
        y2="11.1708"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A3A3A3" />
        <stop
          offset="1"
          stop-color="#646464"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_231_486"
        x1="33.969"
        y1="0"
        x2="33.969"
        y2="67.938"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CFCFCF" />
        <stop
          offset="1"
          stop-color="#EEEEEE"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "NewspaperImage",
};
</script>
