<template>
  <div>
    <v-card-text align="center" class="my-0 card-text">
      <v-form ref="form" class="my-0">
        <div class="amount-group">
          <v-text-field
          v-model="selectedAmount"
          class="amount-input centered-input"
          type="number"
          :rules="authorizedAmountsRules"
          prefix="€"
          />
          <v-chip-group
            v-model="selectedAmount"
            active-class="primary-btn text--accent-4"
            class="chip-group"
            center-active
          >
            <v-chip large class="amount-chip my-2" v-for="amount in chipAmounts" :key="amount" :value="amount" @click="selectAmount(amount)">
              <span class="chip-text">{{ amount }}€</span>
            </v-chip>
          </v-chip-group>
        </div>
        <div class="mt-10">
          <v-select
              :items="creditCardList"
              item-value="id"
              v-model="selectedCreditCardId"
              label="Sélectionnez une carte"
              outlined
          >
            <template v-slot:selection="{item}">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      <v-img
                        v-if="item.cardProviderLogo"
                        :src="item.cardProviderLogo"
                        :alt="item.cardProvider"
                        max-width="40"
                      ></v-img>
                      <v-icon v-else large>mdi-credit-card-outline</v-icon>
                      <v-spacer></v-spacer>
                      <span>{{ item.alias }}</span>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" :default="{ active }" >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      <span>{{ item.alias }}</span>
                      <v-spacer></v-spacer>
                      <span>{{ item.cardProvider }}</span>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="selectedCreditCardId == -1"
        :href="appUrl + '/recharge-wallet/payment-info?amount=' + selectedAmount"
        target="_blank"
        block
        class="primary-btn"
      >
        Ajouter une nouvelle carte
      </v-btn>
      <v-btn
        v-else
        block
        @click="submit"
        :disabled="isRechargeWalletProcessing"
        :loading="isRechargeWalletProcessing"
        class="primary-btn"
      >
        Recharger {{ selectedAmount }}€
      </v-btn>
    </v-card-actions>
  </div>

</template>

<script>
import {
  appUrl
} from "@/env";

export default {
  async mounted() {
    await this.$store.dispatch("fetchUserCreditCard");
  },
  data() {
    return {
      authorizedAmountsRules : [
        (v) => !!v || "Veuillez indiquer un montant",
        (v) => (v && v >= 5) || "5€ est le minimum autorisé",
        (v) => (v && v <= 100) || "100€ est le maximum accepté",
      ],
      selectedCreditCardId: this.$store.state.creditCards[0].id,
      chipAmounts: [5, 10, 15],
      attrs: undefined,
      on: undefined,
      selectedAmount: 5,
      isRechargeWalletProcessing: false,
      appUrl,
    };
  },
  computed: {
    creditCardList() {
      const creditCardList = this.$store.state.creditCards
      if(!creditCardList.some(e => e.id == -1)) {
        creditCardList.push({
          alias: "+ Ajouter une nouvelle Carte",
          cardProviderLogo: null,
          id: -1
        })
      }
      return creditCardList
    } 
  },
  methods: {
    browserInfo() {
      return {
        accept_header:
          "text/html, application/xhtml+xml, application/xml;q=0.9, /;q=0.8",
        language: "FR-FR",
        screen_height: window.innerHeight,
        screen_width: window.innerWidth,
        color_depth: screen.colorDepth,
        java_enabled: true,
        javascript_enabled: true,
        timezone_offset: 60,
        user_agent: navigator.userAgent,
      };
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.isRechargeWalletProcessing = true;
        const rechargeWalletPayload = {
          card_id: this.selectedCreditCardId,
          amount_in_cents: 100 * this.selectedAmount,
          browser_info: this.browserInfo(),
          method: "REGISTERED_CARD",
          env: "WIDGET",
          origin_url: window.location.href
        };
        await this.$store.dispatch('createRechargeWallet', rechargeWalletPayload);
      }
    },
    selectAmount(amount) {
      this.selectedAmount = amount
    }
  },
};
</script>

<style>
.card-text {
  justify-content: center;
}

.amount-input {
  max-width: 50%;
}

.chip-group .v-slide-group__content {
  justify-content: space-around;
}

.amount-chip {
  min-width: 10vh;
  justify-content: center;
  margin-right: 10vh;
}

.chip-text {
  font-size: large;
}

.v-text-field {
  font-size: xx-large;
}

.v-text-field input {
  text-align: center;
}

.amount-group {
  padding-bottom: 20px;
}
</style>
