import Vue from 'vue';
import App from '../App.vue';
import store from '../store/index.js';
import vueCustomElement from 'vue-custom-element';

const init = () => new Promise((resolve) => {
    App.store = store;
    Vue.use(vueCustomElement);
    Vue.customElement('passepartout-widget', App, {
      shadow: true,
      beforeCreateVueInstance(root) {
          const rootNode = root.el.getRootNode();

          if (rootNode instanceof ShadowRoot) {
            // eslint-disable-next-line no-param-reassign
            const style = document.createElement('style');
            style.textContent = `
              :host {
                font-size: 14px;
              }
            `;
            rootNode.appendChild(style);
            root.shadowRoot = rootNode;
          } else {
            // eslint-disable-next-line no-param-reassign
            root.shadowRoot = document.head;
          }
          return root;
        },
    })

    // Monkey patch querySelector to properly find root element
    // cf. https://github.com/vuetifyjs/vuetify/issues/7622
    const { querySelector } = document
    document.querySelector = function (selector) {
      if (selector === '[data-app]') return document.getElementById('passepartout-widget').shadowRoot
      return querySelector.call(this, selector)
    }

    resolve()
})

export default {
    init
}