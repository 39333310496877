<template>
  <v-card class="main-card">
    <generic-error v-if="$store.state.screen.genericError" />

    <template v-else-if="$store.state.keycloak.authenticated">
      <progress-with-message v-if="!!$store.state.progressMessage" />
      <do-not-use-temp-email v-else-if="$store.state.isTempEmail" />
      <verify-email v-else-if="!$store.state.userProfile.is_verified_email" />
      <access-already-available v-else-if="$store.state.screen.accessAlreadyAvailable" />
      <buy-feedback v-else-if="$store.state.screen.buyFeedback" />
      <recharge-wallet v-else-if="$store.state.screen.rechargeWallet" />
      <buy-confirm v-else />
    </template>

    <sign-up
      v-else
      :attach="$parent.$el"
    />
  </v-card>
</template>

<script>
import SignUp from "./SignUp.vue";
import AccessAlreadyAvailable from "./AccessAlreadyAvailable.vue";
import BuyConfirm from "./BuyConfirm.vue";
import BuyFeedback from "./BuyFeedback.vue";
import ProgressWithMessage from "./ProgressWithMessage.vue";
import RechargeWallet from "./RechargeWallet.vue"
import GenericError from "./GenericError.vue"
import VerifyEmail from "./VerifyEmail.vue"
import DoNotUseTempEmail from "./DoNotUseTempEmail.vue"

export default {
  name: "MainCard",
  components: {
    AccessAlreadyAvailable,
    BuyConfirm,
    BuyFeedback,
    SignUp,
    ProgressWithMessage,
    RechargeWallet,
    GenericError,
    DoNotUseTempEmail,
    VerifyEmail,
  },
  data() {
    return {
      tabFocus: true,
    }
  },
  methods: {
    async checkUserEligibility() {
      await this.$store.dispatch('fetchUserEligibility', {
        amount_in_cents: this.$store.state.contentPriceInCents,
        external_content_id: this.$store.state.contentId,
        url: this.$store.state.contentUrl,
        publisher_id: this.$store.state.publisherId,
      });
    }
  },
  created() {
    window.addEventListener('focus', this.checkEligibility);
  },
  destroyed() {
    window.removeEventListener('focus', this.checkEligibility)
  }
};
</script>

<style lang="scss">
.main-card {
  border-radius: 20px 20px 0px 0px !important;
  padding: 8px;
}

.main-card-link {
  color: inherit !important;
  text-decoration-color: var(--v-primary-base);
  text-decoration-thickness: 3px;
}

.main-card-link:hover {
  color: var(--v-primary-base) !important;
}

.main-card-text {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
}
</style>
