<template>
  <div>
    <div
      class="pt-4 main-card-text"
      style="text-align: left"
    >
      <v-row style="max-height: 150px; overflow: hidden">
        <v-col
          v-if="$vuetify.breakpoint.width > 370"
          cols="3"
          class="pr-1"
        >
          <img class="article-img" :src="$store.state.contentThumbnail" />
        </v-col>

        <v-col>
          <span class="body-1 font-weight-medium" :class="$store.state.contentLede ? 'one-line' : 'three-lines'" style="line-height: normal !important;">
            {{ $store.state.contentTitle }}
          </span>
          <span class=" mt-1 body-2 font-weight-medium text--secondary article-description three-lines" style="line-height: normal !important;">
            {{ $store.state.contentLede }}
          </span>
        </v-col>
      </v-row>

      <v-row v-if="$store.state.balanceType === 'PUBLISHER_CONTENT_CREDIT' || $store.state.balanceType === 'GENERAL_CONTENT_CREDIT' ">
        <v-col cols="6">
          <div class="text-caption">Prix de l'article</div>
          <div class="text-h6 font-weight-bold">
            <span class="font-weight-light text-decoration-line-through text--secondary">
              {{ $store.state.contentPriceInCents / 100 }}€
            </span>
            <span class="pl-1">
              offert
            </span>
          </div>
        </v-col>

        <v-col>
          <div class="text-caption text--secondary">Mon ardoise</div>
          <div class="text-h6 font-weight-medium">
            {{$store.state.userBalance}} article{{$store.state.userBalance > 1 ? 's' : ''}} offert{{$store.state.userBalance > 1 ? 's' : ''}}
          </div>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="6">
          <div class="text-caption">Prix de l'article</div>
          <div class="text-h6 font-weight-bold">
            <span>
              {{ $store.state.contentPriceInCents / 100 }}€
            </span>
          </div>
        </v-col>

        <v-col>
          <template v-if="$store.state.eligibilityStatus === 'NO_MANDATE'">
            <div
              class="text-caption font-weight-bold"
              style="color: var(--v-error-base)"
            >Mon ardoise</div>
            <div
              class="text-h6 font-weight-bold"
              style="color: var(--v-error-base)"
            >
              {{ $store.state.userBalance / 100 }}€
            </div>
          </template>

          <template v-else>
            <div class="text-caption">Mon ardoise</div>
            <div class="text-h6 font-weight-medium">
              {{ $store.state.userBalance / 100 }}€
            </div>
          </template>
        </v-col>
      </v-row>

      <div class="pt-4 text-caption text--secondary">
        <span v-if="$store.state.balanceType === 'PUBLISHER_CONTENT_CREDIT' || $store.state.balanceType === 'GENERAL_CONTENT_CREDIT' ">
          Accédez à cet article gratuitement grâce à votre article offert.
        </span>

        <span v-else-if="$store.state.eligibilityStatus === 'FIRST_PURCHASES'">
          Vous devrez bientôt <a :href="setupTabUrl" target="_blank">activer votre ardoise</a> pour continuer de profiter de l'achat à l'article.
        </span>

        <span v-else-if="$store.state.eligibilityStatus === 'NO_MANDATE'" style="color: var(--v-error-base)">
          Activez votre ardoise pour continuer de profiter de l'achat à l'article.
        </span>

        <span v-else-if="$store.state.balanceType === 'PASSEPARTOUT_TAB' && $store.state.contentPriceInCents + $store.state.userBalance > 500">
          ℹ️ Après cet achat votre ardoise dépassera 5€ et sera donc débitée.
        </span>

        <span v-else>
          Votre ardoise sera débitée lorsqu'elle dépassera 5€.
        </span>
      </div>
    </div>

    <v-card-actions>
      <v-btn
        v-if="$store.state.eligibilityStatus === 'NO_MANDATE'"
        :href="setupTabUrl"
        target="_blank"
        block
        class="primary-btn"
      >
        Activer mon ardoise
      </v-btn>
      <v-btn
        v-else
        block
        class="primary-btn"
        @click="buyArticle"
        :loading="isBuyArticleLoading"
      >
        <template v-if="$store.state.balanceType === 'PASSEPARTOUT_TAB'">Acheter l'article</template>
        <template v-else>Obtenir l'article</template>
      </v-btn>
    </v-card-actions>

  </div>

</template>

<script>
import {
  appUrl
} from "@/env";

export default {
  data() {
    return {
      appUrl,
      isBuyArticleLoading: false,
    };
  },
  computed: {
    setupTabUrl() {
      return appUrl + '/setup-tab?originUrl=' + encodeURIComponent(window.location.href)
    }
  },
  methods: {
    async buyArticle() {
      this.isBuyArticleLoading = true;
      await this.$store.dispatch('purchaseContent', {
        amount_in_cents: this.$store.state.contentPriceInCents,
        external_content_id: this.$store.state.contentId,
        url: this.$store.state.contentUrl,
        publisher_id: this.$store.state.publisherId,
        title: this.$store.state.contentTitle,
        lede: this.$store.state.contentLede,
        thumbnail: this.$store.state.contentThumbnail,
      })
    },
    rechargeWalletInWidget() {
      this.$store.commit('setScreen', {
        rechargeWallet: true
      })
    },
  },
};
</script>

<style>
.article-img {
  max-width: 75px;
  width: 100%;
  max-height: 75px;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.one-line {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.three-lines {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}
</style>
