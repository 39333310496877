<template>
  <div>
    <v-layout
      justify-center
      align-center
    >
      <v-icon x-large>{{ mdiEmailFast }}</v-icon>
    </v-layout>

    <v-card-title class="justify-center">
      Un email vous a été envoyé
    </v-card-title>

    <v-card-text
      align="center"
      v-if="!$store.state.genericErrorText"
    >
      Nous n'avons pas pu vérifier votre email automatiquement. Cliquez sur le lien que contient l'email pour obtenir votre article offert.
    </v-card-text>
    <v-card-text v-else>
      {{ errorText }}
    </v-card-text>
    <v-card-actions class="flex-column">
      <v-btn
        width="100%"
        :loading="isVerificationEmailSending"
        @click="sendVerificationEmail"
      >
        Renvoyer l'email
      </v-btn>
      <v-btn
        width="100%"
        color="mt-2 primary-btn"
        @click="isEmailVerified"
      >
        J'ai vérifié mon email
        <v-icon>{{ mdiCheck }}</v-icon>
      </v-btn>
    </v-card-actions>
    <div v-if="displayHint" class="pb-1 text-center text-caption text--secondary">
      Pensez à vérifier vos spams 💡
    </div>
  </div>
</template>

<script>
import {
  mdiEmailFast,
  mdiCheck,
} from '@mdi/js'

export default {
  data() {
    return {
      mdiEmailFast,
      mdiCheck,
      isButtonLoading: false,
      displayHint: false,
      isVerificationEmailSending: false,
    }
  },
  computed: {
    errorText() {
      return this.$store.state.genericErrorText
    }
  },
  methods: {
    async goBuyConfirm() {
      this.$store.commit('setScreen', {
        genericError: false
      })
      this.$store.commit('setStoreParam', {
        key: 'genericErrorText',
        value: null
      })
    },
    async isEmailVerified() {
      await this.$store.dispatch('fetchUserEligibility', {
        amount_in_cents: this.$store.state.contentPriceInCents,
        external_content_id: this.$store.state.contentId,
        url: this.$store.state.contentUrl,
        publisher_id: this.$store.state.publisherId,
      });
      await this.$store.dispatch("fetchUserData");
    },
    async sendVerificationEmail() {
      this.isVerificationEmailSending = true
      await this.$store.dispatch("sendVerificationEmail");
      this.isVerificationEmailSending = false
    }
  },
}
</script>
