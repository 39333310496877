var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('v-card-title',[_c('v-spacer'),_c('img',{staticClass:"publisher-logo",attrs:{"src":this.$store.state.publisher.logoUrl}}),_c('v-spacer')],1),_c('div',{staticClass:"main-card-text text-body-1"},[_vm._v(" Connectez-vous avec "),_c('a',{staticClass:"main-card-link",attrs:{"href":"http://passepartout.io/a-propos","target":"_blank"}},[_vm._v("PassePartout")]),_vm._v(" partenaire "+_vm._s(_vm.displayedPartnershipSentence)+" pour acheter cet article ("+_vm._s((_vm.$store.state.contentPriceInCents / 100).toFixed(2))+"€). ")]),_c('v-card-actions',{staticClass:"flex-column"},[_c('v-btn',{attrs:{"target":"_blank","block":""},on:{"click":function($event){return _vm.$store.state.keycloak.login({
        redirectUri: _vm.$store.getters.keycloakRedirectUri,
        locale: 'fr'
      })}}},[_vm._v(" J'ai déjà un compte ")]),_c('v-btn',{staticClass:"mt-2 primary-btn",attrs:{"block":""},on:{"click":function($event){return _vm.$store.state.keycloak.login({
          action: 'register',
          locale: 'fr',
          redirectUri: _vm.$store.getters.keycloakRedirectUri,
        })}}},[_vm._v(" Créer mon compte* ")])],1),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-2 text-center text-caption text--secondary"},[_vm._v(" *En 10s ⏱, puis obtenez cet article "),_c('span',{staticClass:"text-decoration-underline"},[_vm._v("gratuitement")]),_vm._v(" ✨ ")])
}]

export { render, staticRenderFns }