<template>
  <div>
    <v-card-title class="flex-column justify-center">
      <v-icon
        style="color: var(--v-success-base)"
        light
        size="60"
      >{{ mdiCheckboxMarkedCircle }}</v-icon>
      <span class="mt-2">
        Achat réussi !
      </span>
    </v-card-title>

    <div class="main-card-text text-center text-body-1">
      Vous pouvez dès à présent consulter votre article.
    </div>
    
    <v-card-actions class="flex-column">
      <v-btn
        block
        :href="appUrl"
        target="_blank"
      >
        Voir mon historique
      </v-btn>
      <v-btn
        block
        class="mt-2 primary-btn"
        @click="accessContent"
      >
        Accéder à l'article
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import {
  appUrl
} from "@/env";
import {
  mdiCheckboxMarkedCircle
} from '@mdi/js'

export default {
  data() {
    return {
      mdiCheckboxMarkedCircle,
      appUrl,
    }
  },
  methods: {
    async accessContent() {
      this.$store.commit('closeDialog')
    }
  },
}
</script>
