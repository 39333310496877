import { render, staticRenderFns } from "./MainDialog.vue?vue&type=template&id=5bb91797"
import script from "./MainDialog.vue?vue&type=script&lang=js"
export * from "./MainDialog.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./MainDialog.vue?vue&type=style&index=0&id=5bb91797&prod&lang=css")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports