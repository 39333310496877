import Vue from 'vue'
import Keycloak from "keycloak-js";
import axios from "axios";
import store from './store'
import updateToken from "@/middlewares/update-token";
import { keycloakUrl, keycloakRealm } from "@/env";
import initWidget from './modules/initWidget.js';
import 'document-register-element/build/document-register-element'
import PassepartoutWidget from './PassepartoutWidget.js';
import eventsManager from "./modules/eventsManager";
import App from "./App.vue";

Vue.config.productionTip = false;
Vue.config.silent = true;

const originalError = console.error;
console.error = function (...args) {
  if (String(args[0]).includes("reading '_injectedStyles'")) {
    return
  }
  originalError.apply(console, args);
};

function tokenInterceptor() {
  // Add a request interceptor to add the token to the headers
  
  axios.interceptors.request.use(
    async (config) => {
      if (Vue.prototype.$keycloak.authenticated) {
        const token = await updateToken();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

function getSilentCheckSsoUrl() {
  // Check if silent-check-sso.txt exists, if not, check if silent-check-sso.html exists
  // Ouest-France utilise une URL .txt
  // Mais pas moyen de faire fonctionner en .txt sur nos app vue, donc on utilise .html

  const htmlUrl = `${window.location.origin}/silent-check-sso.html`;
  const txtUrl = `${window.location.origin}/silent-check-sso.txt`;

  return fetch(txtUrl, { method: 'HEAD' })
    .then(response => {
      if (response.ok) {
        return txtUrl;
      } else {
        return fetch(htmlUrl, { method: 'HEAD' }).then(response => {
          if (response.ok) {
            return htmlUrl;
          } else {
            throw new Error('No silent-check-sso file found');
          }
        });
      }
    });
}

const keycloak = new Keycloak({
  realm: keycloakRealm,
  url: keycloakUrl + '/auth',
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
});

getSilentCheckSsoUrl().then(silentCheckSsoUrl => {
  keycloak.init({
    onLoad: "check-sso",
    pkceMethod: "S256",
    silentCheckSsoRedirectUri: silentCheckSsoUrl,
    silentCheckSsoFallback: false, 
  }).then((authenticated) => {
    Vue.prototype.$keycloak = keycloak
    Vue.prototype.$keycloak.authenticated = authenticated;

    store.commit("setKeycloak", keycloak);

    tokenInterceptor();

    initWidget.init().then(async () => {
      window.PassepartoutWidget = PassepartoutWidget;

      // fetchUserData
      if (Vue.prototype.$keycloak.authenticated && !store.state.isUserDataFetched) {
        await store.dispatch('fetchUserData')
      }
      
      // on envoie un événement pour signaler que le widget est chargé
      eventsManager.trigger('passepartout-widget:loaded', {
        'contentId': store.state.contentId,
        'contentTitle': store.state.contentTitle,
        'contentLede': store.state.contentLede,
        'contentPriceInCents': store.state.contentPriceInCents,
        'contentUrl': store.state.contentUrl,
        'publisherId': store.state.publisherId,
      });
    })
    new Vue({
      render: (h) => h(App),
    }).$mount("#app");
  }).catch(error => {
  console.error(error);
  })
});
