<template>
  <div>
    <v-card-title class="flex-column justify-center">
      Vous avez déjà accès à cet article 🤔
    </v-card-title>

    <v-card-text>
      Si le contenu de l'article ne s'affiche pas, il s'agit certainement d'un problème technique. Cliquez sur le bouton ci-dessous pour nous le signaler et nous investiguerons.
    </v-card-text>

    <v-card-actions class="flex-column">
      <v-btn
        @click="sendReport"
        target="_blank"
        block
      >
        Envoyer un rapport
      </v-btn>
      <v-btn
        :href="appUrl"
        target="_blank"
        block
        class="mt-2 primary-btn"
      >
        Voir mon historique
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import {
  appUrl
} from "@/env";

export default {
  data() {
    return {
      appUrl,
    };
  },
  methods: {
    sendReport() {
      return
      // TODO send current state to the API for debugging & monitoring purposes.
    }
  }
};
</script>
