import axios from 'axios';
import { apiUrl } from "@/env";

function authHeaders(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async getMeOrCreate(token, redirectUri) {
    return axios.post(
      `${apiUrl}/v1/users/get-or-create`,
      { redirect_uri: redirectUri },
      authHeaders(token)
    );
  },
  async getUserEligibility(token, data) {
    return axios.post(
      `${apiUrl}/v1/tab/eligibility`,
      data,
      authHeaders(token)
    );
  },
  async getUserAccessRights(token, userId, contentId) {
    return axios.get(`${apiUrl}/v1/access-rights?user_id=${userId}&content_id=${contentId}`, authHeaders(token));
  },
  async purchaseContent(token, data) {
    return axios.post(
      `${apiUrl}/v1/tab/purchase-content`,
      data,
      authHeaders(token)
    );
  },
  async checkAccessRight(clientSsoIdentifier, clientSsoUserId, publisherId, externalContentId, apiKey) {
    return axios.get(
      `${apiUrl}/v1/access-rights/check?client_sso_identifier=${clientSsoIdentifier}&client_sso_user_id=${clientSsoUserId}&publisher_id=${publisherId}&external_content_id=${externalContentId}&publisher_api_key=${apiKey}`
    );
  },
  async getCreditCards(token, only_active) {
    return axios.get(
      `${apiUrl}/v1/payments/credit-cards?only_active=${only_active}`,
      authHeaders(token)
    );
  },
  async createRechargeWallet(token, data) {
    return axios.post(
      `${apiUrl}/v1/payments/recharge-wallet`,
      data,
      authHeaders(token)
    );
  },
  async getPayin(token, payinId) {
    return axios.get(
      `${apiUrl}/v1/payments/mangopay-payin/${payinId}`,
      authHeaders(token)
    );
  },
  async sendVerificationEmail(token, data) {
    return axios.post(
      `${apiUrl}/v1/send-verification-email`,
      data,
      authHeaders(token)
    );
  },
};
