
function trigger(name, data) {
    let event;

    if (window.CustomEvent) {
        event = new CustomEvent(name, {
            detail: data,
        });
    } else {
        event = document.createEvent(name);
        // @NOTE: according to MDN:
        // This feature has been removed from the Web standards.
        // Though some browsers may still support it,
        // it is in the process of being dropped.
        // Do not use it in old or new projects.
        // Pages or Web apps using it may break at any time.
        event.initCustomEvent(name, true, true, data);
    }

    const passepartoutWidget = document.getElementById('passepartout-widget');
    if (passepartoutWidget) {
        passepartoutWidget.dispatchEvent(event);
    }
}

export default {
    trigger,
};
