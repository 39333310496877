<template>
  <div>
    <v-layout
      justify-center
      align-center
    >
      <v-icon x-large>{{ mdiEmailFast }}</v-icon>
    </v-layout>

    <v-card-title class="justify-center">
      Vous utilisez un email temporaire ? 🙄
    </v-card-title>

    <v-card-text align="center">
      L'utilisation de PassePartout nécessite un email valide.
      <br />Rassurez-vous, <b>nous n'envoyons aucun spam</b>.<br />
    </v-card-text>

    <v-card-actions class="flex-column">
      <v-btn
        width="100%"
        color="mt-2 primary-btn"
        @click="$store.state.keycloak.logout({ redirectUri: $store.getters.keycloakRedirectUri })"
      >
        Utiliser un email valide
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import {
  mdiEmailFast,
} from '@mdi/js'

export default {
  data() {
    return {
      mdiEmailFast,
    }
  },
}
</script>
