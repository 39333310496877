<template>
  <div class="text-center">
    <v-card-title>
      <v-spacer />
      <img class="publisher-logo" :src="this.$store.state.publisher.logoUrl" />
      <v-spacer />
    </v-card-title>
    <div class="main-card-text text-body-1">
      Connectez-vous avec
      <a class="main-card-link" href="http://passepartout.io/a-propos" target="_blank">PassePartout</a>
      partenaire {{ displayedPartnershipSentence }} pour acheter cet article ({{ ($store.state.contentPriceInCents / 100).toFixed(2) }}€).
    </div>
    <v-card-actions class="flex-column">
      <v-btn
        @click="$store.state.keycloak.login({
          redirectUri: $store.getters.keycloakRedirectUri,
          locale: 'fr'
        })"
        target="_blank"
        block
      >
        J'ai déjà un compte
      </v-btn>
      <v-btn
        @click="
          $store.state.keycloak.login({
            action: 'register',
            locale: 'fr',
            redirectUri: $store.getters.keycloakRedirectUri,
          })
        "
        class="mt-2 primary-btn"
        block
      >
        Créer mon compte*
      </v-btn>
    </v-card-actions>
    <div class="pb-2 text-center text-caption text--secondary">
      *En 10s ⏱, puis obtenez cet article
      <span class="text-decoration-underline">gratuitement</span> ✨
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    displayedPartnershipSentence() {
      if (this.$store.state.publisher.name.startsWith("Le ")) {
        return "du " + this.$store.state.publisher.name.substring(3);
      }
      return "de " + this.$store.state.publisher.name;
    },
  },
};
</script>

<style scoped>
.publisher-logo {
  height: 40px;
  border-radius: 8px;
}
</style>
