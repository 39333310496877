<template>
  <div>
    <v-layout justify-center align-center>
      <v-icon
      color="red"
      x-large
      >{{ mdiCancel }}</v-icon>
    </v-layout> 
    <v-card-title class="justify-center">
        Une erreur s'est produite !
    </v-card-title>
    <v-card-text v-if="!$store.state.genericErrorText">
      Un problème inattendu est survenu. <br/>Imposible de traiter votre demande, veuillez réessayer.
    </v-card-text>
    <v-card-text v-else>
      {{ errorText }}
    </v-card-text>
    <v-card-actions>
      <v-btn
        width="100%"
        color="primary-btn"
        @click="goBuyConfirm"
      >
        Réessayer
      </v-btn>
    </v-card-actions>
  </div>
</template>
  
<script>
import {
  mdiCancel,
} from '@mdi/js'

export default {
  data() {
    return {
      mdiCancel,
    }
  },
  computed: {
    errorText() {
      return this.$store.state.genericErrorText
    }
  },
  methods: {
    async goBuyConfirm() {
      this.$store.commit('setScreen', {
        genericError: false
      })
      this.$store.commit('setStoreParam', { key: 'genericErrorText', value: null})
    }
  },
}
</script>