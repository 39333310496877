export const getters = {
    keycloakRedirectUri() {
        let url = new URL(window.location.href);
        let urlParams = new URLSearchParams(url.search);
        if (!urlParams.has('openWidget')) {
            url.searchParams.append('openWidget', true);
        }
        return url.toString()
    },
}
