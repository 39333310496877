<template>
  <div>
    <v-card-title class="flex-column justify-center">
      <v-progress-circular
        indeterminate
        :color="$vuetify.theme.themes.light.primary"
      ></v-progress-circular>
    </v-card-title>

    <div class="main-card-text text-center text-body-1">
      {{ this.$store.state.progressMessage }}
    </div>
  </div>
</template>

<script>
export default {};
</script>
